<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">GROUPS</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <el-table
      :data="dataGroups"
      style="width: 100%">
      <el-table-column
        label="Name">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.name"></el-input>
            </template>
      </el-table-column>
      <el-table-column
        label="Discount">
            <template #default="scope">
              <el-input-number size="small"
                :controls="false"
                style="text-align:center"
                v-model="scope.row.discount"></el-input-number>
            </template>
      </el-table-column>
      <el-table-column
        label="is Vip">
            <template #default="scope">
              <el-switch size="small" v-model="scope.row.isVip"></el-switch>
            </template>
      </el-table-column>
      <el-table-column
        label="is One Click">
            <template #default="scope">
              <el-switch size="small" v-model="scope.row.isOneClick"></el-switch>
            </template>
      </el-table-column>
      <el-table-column
        label="Comment">
            <template #default="scope">
              <el-input size="small"
                type="textarea"
                :rows="1"
                style="text-align:center"
                v-model="scope.row.comment"></el-input>
            </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">

            <div v-if="scope.$index >= groups.length">
                <el-button
                 @click.native.prevent="addGroup(scope.row)" type="text" size="small">
                    Create
                </el-button>
            </div>
            <div class="flex gaps" v-else>
                <el-button @click.native.prevent="saveRow(scope.row)" type="text" size="small">
                    Save
                </el-button>
                <el-button @click.native.prevent="deleteRow(scope.row, scope.$index)" type="text" size="small">
                    Delete
                </el-button>
            </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex justify-flex-end m-10">
        <el-button @click="addRow()" size="small">
            Add Group
        </el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Group',

  data () {
    return {
      dataGroups: []
    }
  },

  computed: {
    ...mapState({
      groups: state => state.group.groups
    })
  },

  methods: {
    deleteRow (row, index) {
      this.$store.dispatch('removeGroup', row._id)
        .then(() => {
          this.dataGroups.splice(index, 1)
        })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Group removed successfully',
            type: 'success'
          })
        })
    },
    saveRow (row) {
      this.$store.dispatch('editGroup', {
        id: row.uid,
        content: row
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Group edited successfully',
            type: 'success'
          })
        })
    },
    addGroup (payload) {
      this.$store.dispatch('addGroup', payload)
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Group created successfully',
            type: 'success'
          })
        })
    },
    addRow () {
      let newRow = {
        name: '',
        discount: 0,
        isVip: false,
        isOneClick: false,
        comment: ''
      }
      this.dataGroups = [...this.dataGroups, newRow]
    }
  },
  mounted () {
    this.dataGroups = this.groups.map((x) => Object.assign({}, x))
  }
}
</script>

<style lang="scss">
</style>
